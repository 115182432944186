import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getEcommercePath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/odvetvi/e-commerce",
		[Locale.en]: "/industries/e-commerce",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
